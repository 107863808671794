import request from '@/utils/request'

const basicConfig = {
  // 基础配置管理
  // 基础配置添加编辑
  editConfig(params) {
    return request.post("config/edit", params)
  },
  // 基础配置状态
  statusConfig(params) {
    return request.post("config/status", params)
  },
  // 基础配置删除
  delConfig(params) {
    return request.post("config/del", params)
  },
  // 基础配置详情
  infoConfig(params) {
    return request.post("config/info", params)
  },
  // 基础配置列表
  listConfig(params) {
    return request.post("config/list", params)
  },
  //弹窗管理列表  要按基础配置管理配置
  listInstallPosition(params){
    return request.post("installposition/list",params)
  },
  // 绑定店铺
  tiktok_shop_bind_poi(params){
    return request.post("tiktok/shop_bind_poi",params)
  },
  // 车型信息库管理
  // 品牌添加编辑
  editCarbrand(params) {
    return request.post("carbrand/edit", params)
  },
  // 品牌详情
  infoCarbrand(params) {
    return request.post("carbrand/info", params)
  },
  // 品牌状态
  statusCarbrand(params) {
    return request.post("carbrand/status", params)
  },
  // 品牌列表
  listCarbrand(params) {
    return request.post("carbrand/list", params)
  },
  // 品牌删除
  delCarbrand(params) {
    return request.post("carbrand/del", params)
  },
  // 车型添加编辑
  editCarbase(params) {
    return request.post("carbase/edit", params)
  },
  // 车型详情
  infoCarbase(params) {
    return request.post("carbase/info", params)
  },
  // 车型状态
  statusCarbase(params) {
    return request.post("carbase/status", params)
  },
  // 车型列表
  listCarbase(params) {
    return request.post("carbase/list", params)
  },
  // 车型删除
  delCarbase(params) {
    return request.post("carbase/del", params)
  },
  // 首字母列表
  letterCarbase(params) {
    return request.post("carbase/letter", params)
  },
  // 车型菜单
  menuCarbase(params) {
    return request.post("carbase/menu", params)
  },
  // 绑定门店回显tiktok/verifyed_list
  tiktok_verifyed_list(params){
    return  request({
      method:'get',
      url:'tiktok/verifyed_list',
      params,
    })
  }

}

export default basicConfig;