// 汇总所有模块的请求
import admin from './modules/admin'
import powerManage from './modules/powerManage'
import storeManage from './modules/storeManage'
import serviceProducts from './modules/serviceProducts'
import basicConfig from './modules/basicConfig'
import staffManage from './modules/staffManage'
import orderManage from './modules/orderManage'
import marketTools from './modules/marketTools'
import userManage from "./modules/userManage"
import dataManage from "./modules/dataManage"
import washTool from "./modules/washTool"
import diy from './modules/diy'

const api = {
  admin, powerManage, storeManage, serviceProducts, 
  basicConfig,staffManage,orderManage,marketTools,userManage,dataManage,washTool,diy
}

export default api;