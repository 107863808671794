import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';  //本地持久化插件

import tabs from './modules/tabs'
import userInfo from './modules/userInfo'
import locales from './modules/locales';
import listData from './modules/listData';
import order from './modules/order';
// const tab = createPersistedState({
//   key: 'tab',
//   paths: ['tabs']
// })

const userInfos = createPersistedState({
  key: 'userInfo',
  paths: ['userInfo']
})

const locale = createPersistedState({
  key: 'locales',
  paths: ['locales']
})

const listDatas = createPersistedState({
  key: 'listData',
  paths: ['listData']
})
// const orders = createPersistedState({
//   key: 'order',
//   paths: ['order']
// })

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    tabs,
    userInfo,
    locales,
    listData,
    order
  },
  plugins: [
    // tab,
    userInfos, locale,listDatas,
  ]
})
