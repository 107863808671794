<template>
  <div class="container">
    <div class="tabs-header" id="nav" @mouseover="mouseOver">
      <div class="tabs" v-for="(item, index) in tabsList " :key="index"
      :class="[{ active: idx === index }, { deactive: idx !== index }]" @click="change(index, item.path)">
      <span class="textSpan"> {{ item.title }} </span> <span class="icon"> <el-icon class="el-icon-close"
          @click.native.stop="del(index)"></el-icon> </span>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      idx: 0
    }
  },
  created() {
    this.idx = this.$store.state.tabs.index;
  },
  mounted(){
    
  },
  computed: {
    // tabs循环的数组
    tabsList() {
      return this.$store.state.tabs.tabs
    },
  },
  watch: {
    //  watch侦听vuex的Tabs数据的变化
    '$store.state.tabs.index'(newValue) {
      this.idx = newValue
    }
  },
  methods: {
    change(index, path) {
      this.idx = index
      // 点击tabs栏进行切换, 避免重复跳转
      if (this.$route.path !== path) {
        this.$router.push(path)
      }
    },
    // 删除tabs
    del(index) {
      this.$store.commit('tabs/delTabs', index)
    },
    //鼠标移入事件
    mouseOver(){
      // this.scrollInit()
    },
  }
}

</script>

<style  lang="scss" scoped>
.container{
  height: 100%;
  display: flex;
  align-items: center;
  border-color: white;
}
.tabs-header {
   display: flex;
  //  margin-top: 10%;
   height: 100%;
  //  line-height: 60%;
   width: 100%;
   overflow: hidden;
   background-color: #F5F8FF;
  //  overflow-x: scroll;
 }

 .textSpan{
  white-space: nowrap;
 }
 .tabs {
   display: flex;
   align-items: center;
   //  width: 150px;
   padding: 0 25px 0 10px;
  //  margin-right: 10px;
  //  margin-top: 10px;
  //  margin-bottom: 10px;
   height: 100%;
   //  border-radius: 3px;
   //  min-width: 100px;
   background-color: #f1f1f1;
   position: relative;
   font-size: 12px;


   span {
     margin-left: 6px;
     cursor: pointer;
   }

   .icon {
     position: absolute;
     right: 8px;
    //  top: 15px;
   }
 }

 .deactive {
   background-color: #e8e8e8;
   border-right: 1px solid #f1f1f1;
 }

 .active {
  background-color: rgba(255, 255, 255, 1);
   color: #101010;
   //  box-shadow: 2px 2px 2px 2px #f1f1f1;
   transform: scale(1.1, 1.1);
   transition: all 0.5;
   font-weight: 550;
   font-size: 12px;
   padding-left: 5px;
 }
</style>