import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/views/Layout/index.vue";
import store from '@/store/index.js'
import api from '@/http/api'
// const routerPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function (location) {
//     return routerPush.call(this, location).catch(err => { })
// };

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Layout,
    hidden: true,
    redirect: "/dataManage/dataAnalysis",
  },
  {
    path: '/login',
    hidden: true,
    component: () => import('@/views/Login'),
  },
  {
    path: '/dataManage',
    component: Layout,
    redirect: '/dataManage/dataAnalysis',
    name: '数据管理',
    children: [
      {
        path: 'dataAnalysis',
        name: '数据分析',
        component: () => import('@/views/DataManage/DataAnalysis'),
        meta: {
          title: '数据分析'
        }
      },
      {
        path: "expire",
        name: "到期提醒",
        meta: {
          title: "到期提醒",
        },
        component: () => import("@/views/DataManage/Expire")
      },
    ]
  },
  {
    path: '/basicConfig',
    component: Layout,
    redirect: '/basicConfig/notice',
    name: '基本配置',
    children: [
      {
        path: "notice",
        name: "公告",
        meta: {
          title: "公告",
        },
        component: () => import("@/views/BasicConfig/Notice")
      },
      {
        path: "systemPopup",
        name: "系统弹窗",
        meta: {
          title: "系统弹窗",
        },
        component: () => import("@/views/BasicConfig/SystemPopup")
      },
      {
        path: "vehicleInfo",
        name: "车辆信息",
        meta: {
          title: "车辆信息",
        },
        component: () => import("@/views/BasicConfig/VehicleInfo")
      },
      {
        path: "introDuction",
        name: "平台介绍",
        meta: {
          title: "平台介绍",
        },
        component: () => import("@/views/BasicConfig/IntroDuction")
      },
      {
        path: "payConfig",
        name: "PayConfig",
        meta: {
          title: "支付配置",
        },
        component: () => import("@/views/BasicConfig/PayConfig")
      },
      {
        path: "integralConfig",
        name: "积分配置",
        meta: {
          title: "积分配置",
        },
        component: () => import("@/views/BasicConfig/IntegralConfig")
      },
      {
        path: "transferService",
        name: "接送服务",
        meta: {
          title: "接送服务",
        },
        component: () => import("@/views/BasicConfig/TransferService")
      },
      {
        path: "deliveryService",
        name: "配送服务",
        meta: {
          title: "配送服务",
        },
        component: () => import("@/views/BasicConfig/DeliveryService")
      },
      {
        path: "agreement",
        name: "用户协议",
        meta: {
          title: "用户协议",
        },
        component: () => import("@/views/BasicConfig/agreement")
      },
      {
        path: "privacy",
        name: "隐私政策",
        meta: {
          title: "隐私政策",
        },
        component: () => import("@/views/BasicConfig/privacy")
      },
      {
        path: "yearagreement",
        name: "年检查代办协议",
        meta: {
          title: "年检查代办协议",
        },
        component: () => import("@/views/BasicConfig/yearagreement")
      },
      {
        path: "selfWash",
        name: "自助洗车使用协议",
        meta: {
          title: "自助洗车使用协议",
        },
        component: () => import("@/views/BasicConfig/selfWash")
      },
      {
        path: "vipAgreement",
        name: "VipAgreement",
        meta: {
          title: "会员等级协议",
        },
        component: () => import("@/views/BasicConfig/VipAgreement")
      },
      {
        path: "sanFang",
        name: "sanFang",
        meta: {
          title: "第三方平台验券配置",
        },
        component: () => import("@/views/BasicConfig/sanFang")
      },
    ]
  },
  {
    path: '/powerManage',
    component: Layout,
    redirect: '/powerManage/powerGroup',
    name: '权限管理',
    children: [
      {
        path: "powerGroup",
        name: "权限分组",
        meta: {
          title: "权限分组",
        },
        component: () => import("@/views/PowerManage/PowerGroup")
      },
      {
        path: "rolesManage",
        name: "rolesManage",
        meta: {
          title: "角色管理",
        },
        component: () => import("@/views/PowerManage/RolesManage")
      },
      {
        path: "personManage",
        name: "personManage",
        meta: {
          title: "人员管理",
        },
        component: () => import("@/views/PowerManage/PersonManage")
      },
    ]
  },
  {
    path: '/storeManage',
    component: Layout,
    redirect: '/storeManage/serviceTypeManage',
    name: '门店管理',
    children: [
      {
        path: "serviceTypeManage",
        name: "ServiceTypeManage",
        meta: {
          title: "服务类型管理",
        },
        component: () => import("@/views/StoreManage/ServiceTypeManage")
      },
      {
        path: "storeList",
        name: "StoreList",
        meta: {
          title: "门店列表",
        },
        component: () => import("@/views/StoreManage/StoreList")
      },
    ]
  },
  {
    path: '/staffManage',
    component: Layout,
    redirect: '/staffManage/VehicleReviewDriver',
    name: '员工管理',
    children: [
      {
        path: "vehicleReviewDriver",
        name: "VehicleReviewDriver",
        meta: {
          title: "审车司机",
        },
        component: () => import("@/views/StaffManage/VehicleReviewDriver")
      },

      {
        path: "servicePerson",
        name: "ServicePerson",
        meta: {
          title: "维修人员",
        },
        component: () => import("@/views/StaffManage/ServicePerson")
      },
      {
        path: "towingTechnicians",
        name: "TowingTechnicians",
        meta: {
          title: "拖车师傅",
        },
        component: () => import("@/views/StaffManage/TowingTechnicians")
      },
      {
        path: "reviewDrivers",
        name: "ReviewDrivers",
        meta: {
          title: "救援司机",
        },
        component: () => import("@/views/StaffManage/ReviewDrivers")
      },
      {
        path: "pickDropDrivers",
        name: "PickDropDrivers",
        meta: {
          title: "接送司机",
        },
        component: () => import("@/views/StaffManage/PickDropDrivers")
      },
      {
        path: "beautyMaster",
        name: "BeautyMaster",
        meta: {
          title: "美容师傅",
        },
        component: () => import("@/views/StaffManage/BeautyMaster")
      },
      {
        path: "otherPerson",
        name: "otherPerson",
        meta: {
          title: "门店员工",
        },
        component: () => import("@/views/StaffManage/OtherPerson")
      },
      {
        path: "storePerson",
        name: "storePerson",
        meta: {
          title: "门店管理",
        },
        component: () => import("@/views/StaffManage/StorePerson")
      },
    ]
  },
  {
    path: '/orderManage',
    component: Layout,
    redirect: '/orderManage/orderEmergencyRescue',
    name: '订单管理',
    children: [
      {
        path: "orderEmergencyRescue",
        name: "OrderEmergencyRescue",
        meta: {
          title: "应急救援",
        },
        component: () => import("@/views/OrderManage/EmergencyRescue")
      },
      {
        path: "fastMaintenanceSupport",
        name: "FastMaintenanceSupport",
        meta: {
          title: "快修快保",
        },
        component: () => import("@/views/OrderManage/FastMaintenanceSupport")
      },
      {
        path: "carBeauty",
        name: "CarBeauty",
        meta: {
          title: "汽车美容",
        },
        component: () => import("@/views/OrderManage/CarBeauty")
      },
      {
        path: "carStores",
        name: "CarStores",
        meta: {
          title: "洗车服务",
        },
        component: () => import("@/views/OrderManage/CarStores")
      },
      {
        path: "troubleshoot",
        name: "Troubleshoot",
        meta: {
          title: "故障维修",
        },
        component: () => import("@/views/OrderManage/Troubleshoot")
      },
      {
        path: "inspectAnnually",
        name: "InspectAnnually",
        meta: {
          title: "年检审车",
        },
        component: () => import("@/views/OrderManage/InspectAnnually")
      },
      {
        path: "autoWash",
        name: "autoWash",
        meta: {
          title: "自助洗车服务",
        },
        component: () => import("@/views/OrderManage/AutoWash")
      },
      {
        path: "packageOrder",
        name: "packageOrder",
        meta: {
          title: "优惠套餐",
        },
        component: () => import("@/views/OrderManage/PackageOrder")
      },
      {
        path: "detailPage",
        name: "detailPage",
        component: () => import("@/views/OrderManage/DetailPage")
      },
      {
        path: "tuanGou",
        name: "tuanGou",
        meta: {
          title: "团购订单",
        },
        component: () => import("@/views/OrderManage/TuanGou")
      },
    ]
  },
  {
    path: '/serviceProducts',
    component: Layout,
    redirect: '/serviceProducts/product',
    name: '服务产品',
    children: [
      {
        path: "product",
        name: "Product",
        meta: {
          title: "单产品",
        },
        component: () => import("@/views/ServiceProducts/Product")
      },
      {
        path: "packageServiceClass",
        name: "PackageServiceClass",
        meta: {
          title: "服务管理",
        },
        component: () => import("@/views/ServiceProducts/PackageServiceClass")
      },
      {
        path: "packageClassification",
        name: "PackageClassification",
        meta: {
          title: "套餐分类",
        },
        component: () => import("@/views/ServiceProducts/PackageClassification")
      },
      {
        path: "packageServiceClassEdit",
        name: "packageServiceClassEdit",
        meta: {
          title: "套餐服务分类编辑",
        },
        component: () => import("@/views/ServiceProducts/PackageServiceClass/edit.vue")
      },
      {
        path: "package",
        name: "package",
        meta: {
          title: "优惠套餐",
        },
        component: () => import("@/views/ServiceProducts/WashSerive/package.vue")
      },
      {
        path: "packageDetail",
        name: "packageDetail",
        meta: {
          title: "新增优惠套餐",
        },
        component: () => import("@/views/ServiceProducts/WashSerive/packageDetail.vue")
      },
      // {
      //   path: "emergencyRescueServices",
      //   name: "EmergencyRescueServices",
      //   meta: {
      //     title: "应急救援服务",
      //   },
      //   component: () => import("@/views/ServiceProducts/EmergencyRescueServices")
      // },
      // {
      //   path: "beautyServices",
      //   name: "BeautyServices",
      //   meta: {
      //     title: "美容服务",
      //   },
      //   component: () => import("@/views/ServiceProducts/BeautyServices")
      // },
      // {
      //   path: "carWashService",
      //   name: "CarWashService",
      //   meta: {
      //     title: "洗车服务",
      //   },
      //   component: () => import("@/views/ServiceProducts/CarWashService")
      // },
    ]
  },
  {
    path: '/marketTools',
    component: Layout,
    redirect: '/marketTools/cardCoupon',
    name: '营销工具',
    children: [
      {
        path: "cardCoupon",
        name: "CardCoupon",
        meta: {
          title: "卡券",
        },
        component: () => import("@/views/MarketTools/CardCoupon")
      },
      {
        path: "birthdayMarket",
        name: "BirthdayMarket",
        meta: {
          title: "生日营销",
        },
        component: () => import("@/views/MarketTools/BirthdayMarket")
      },
      {
        path: "marketActivity",
        name: "MarketActivity",
        meta: {
          title: "营销活动发放",
        },
        component: () => import("@/views/MarketTools/MarketActivity")
      },
    ]
  },
  {
    path: '/userManage',
    component: Layout,
    redirect: '/userManage/userList',
    name: '用户管理',
    children: [
      {
        path: "userList",
        name: "UserList",
        meta: {
          title: "用户列表",
        },
        component: () => import("@/views/UserManage/UserList")
      },
      {
        path: "userListDetail",
        name: "userListDetail",
        meta: {
          title: "用户列表详情",
        },
        component: () => import("@/views/UserManage/UserList/detail.vue")
      },
      {
        path: "memberLevel",
        name: "MemberLevel",
        meta: {
          title: "会员等级",
        },
        component: () => import("@/views/UserManage/MemberLevel")
      },
      {
        path: "memberRule",
        name: "MemberRule",
        meta: {
          title: "会员协议",
        },
        component: () => import("@/views/UserManage/MemberRule")
      },
      {
        path: "memberAdd",
        name: "memberAdd",
        meta: {
          title: "新增规则",
        },
        component: () => import("@/views/UserManage/MemberLevel/add.vue")
      },
    ]
  },
  {
    path: '/serviceInstitution',
    component: Layout,
    redirect: '/serviceInstitution/vehicleReviewAgency',
    name: '服务机构',
    children: [
      {
        path: "vehicleReviewAgency",
        name: "VehicleReviewAgency",
        meta: {
          title: "审车机构",
        },
        component: () => import("@/views/ServiceInstitution/VehicleReviewAgency")
      },
      {
        path: "serviceEmergencyRescue",
        name: "ServiceEmergencyRescue",
        meta: {
          title: "应急救援（服务机构）",
        },
        component: () => import("@/views/ServiceInstitution/EmergencyRescue")
      },
    ]
  },
  {
    path: '/messageManagement',
    name: '留言管理',
    component: Layout,
    redirect: "/messageManagement",
    children: [
      {
        path: "message",
        name: "留言管理",
        meta: {
          title: "留言管理",
        },
        component: () => import('@/views/Message/messageManagement.vue'),
      }
    ]

  },
  {
    path: '/WashManage',
    name: '自助洗车机管理',
    component: Layout,
    redirect: "/wash",
    children: [
      {
        path: "wash",
        name: "wash",
        meta: {
          title: "自助洗车",
        },
        component: () => import('@/views/WashManage/wash.vue'),
      },
      {
        path: "free",
        name: "free",
        meta: {
          title: "收费标准配置",
        },
        component: () => import('@/views/WashManage/free.vue'),
      },
      {
        path: "order",
        name: "order",
        meta: {
          title: "自助机订单管理",
        },
        component: () => import('@/views/WashManage/order.vue'),
      },
    ]
  },
  {
    path: '/Distribution',
    name: '营销工具',
    component: Layout,
    redirect: "/center",
    children: [
      {
        path: "center",
        name: "center",
        meta: {
          title: "分销中心",
        },
        component: () => import('@/views/Distribution/center.vue'),
      },
      {
        path: "coupons",
        name: "coupons",
        meta: {
          title: "卡券营销",
        },
        component: () => import('@/views/Distribution/coupons.vue'),
      },
      {
        path: "add",
        name: "add",
        meta: {
          title: "",
        },
        component: () => import('@/views/Distribution/add.vue'),
      },
      // {
      //   path: "birthday",
      //   name: "birthday",
      //   meta: {
      //     title: "",
      //   },
      //   component:()=>import('@/views/Distribution/birthday.vue'),
      // }
    ]
  },
  {
    path: '/DistributionCenter',
    name: 'DistributionCenter',
    component: Layout,
    redirect: "/distributor",
    children: [
      {
        path: "distributor",
        name: "distributor",
        meta: {
          title: "分销商",
        },
        component: () => import('@/views/DistributionCenter/distributor'),
      },
      {
        path: "orders",
        name: "orders",
        meta: {
          title: "分销订单",
        },
        component: () => import('@/views/DistributionCenter/orders'),
      },
      {
        path: "withdrawal",
        name: "withdrawal",
        meta: {
          title: "分销提现",
        },
        component: () => import('@/views/DistributionCenter/withdrawal'),
      },
      {
        path: "base",
        name: "base",
        meta: {
          title: "基础设置",
        },
        component: () => import('@/views/DistributionCenter/base'),
      },
      {
        path: "money",
        name: "money",
        meta: {
          title: "佣金设置",
        },
        component: () => import('@/views/DistributionCenter/money'),
      },
    ]
  },
  {
    path: '/CouponMarketing',
    name: 'CouponMarketing',
    component: Layout,
    redirect: "/list",
    children: [
      {
        path: "list",
        name: "list",
        meta: {
          title: "卡券列表",
        },
        component: () => import('@/views/CouponMarketing/list'),
      },
      {
        path: "add",
        name: "CouponAdd",
        meta: {
          title: "卡券新增",
        },
        component: () => import('@/views/CouponMarketing/list/add.vue'),
      },
      {
        path: "birthday",
        name: "birthday",
        meta: {
          title: "生日营销",
        },
        component: () => import('@/views/CouponMarketing/birthday'),
      },
      {
        path: "birthdayEdit",
        name: "birthdayEdit",
        meta: {
          title: "新增生日卡券",
        },
        component: () => import('@/views/CouponMarketing/birthday/edit.vue'),
      },
      {
        path: "active",
        name: "active",
        meta: {
          title: "营销活动发放",
        },
        component: () => import('@/views/CouponMarketing/active'),
      },
    ]
  },
  {
    path: '/HomeDiy',
    name: 'HomeDiy',
    component: Layout,
    redirect: "/diyList",
    children: [
      {
        path: "diyAdd",
        name: "diyAdd",
        component: () => import('@/views/HomeDiy/Diy'),
      },
      {
        path: "diyList",
        name: "diyList",
        meta: {
          title: "DIY页面",
        },
        component: () => import('@/views/HomeDiy/Diy/list.vue'),
      },
      {
        path: "custom",
        name: "custom",
        meta: {
          title: "自定义页面",
        },
        component: () => import('@/views/HomeDiy/Custom'),
      },
      {
        path: "customAdd",
        name: "customAdd",
        component: () => import('@/views/HomeDiy/Custom/add.vue'),
      },
      {
        path: "bar",
        name: "bar",
        meta: {
          title: "导航设置",
        },
        component: () => import('@/views/HomeDiy/Bar/index.vue'),
      },
    ]
  }
]

const router = new VueRouter({
  // mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})
const whiteList = ['/login'] // no redirect whitelist
var getRouter = ''


router.beforeEach((to, from, next) => {
  // const token = store.state.userInfo.token
  // const locaies = navigator.language
  // const l = locaies === 'zh-CN' ? 'zh' : 'en'  // 语言配置
  // store.commit('locales/setlocal', l)
  // 在跳转前执行逻辑
  // console.log(token);
  // if (!token && to.path !== '/login') {  // 没有token返回登录页
  //   message('请先登陆', 'error')
  //   next('/login')
  // } else {
  //   if (!to.meta.title) {  // 阻止tabs栏出现空白栏
  //     next()
  //   } else {
  // store.commit('tabs/changeRouter', { title: to.meta.title, path: to.path })
  // next(); // 继续跳转
  //   }
  // }
  const hasToken = localStorage.getItem('token')
  let routeList = localStorage.getItem('myRouters')
  console.log('routeList',to);
  if (Object.keys(to.meta).length !== 0) {
    store.commit('tabs/changeRouter', { title: to.meta.title, path: to.path })
  }

  if (hasToken) {
    // console.log('hasToken', to.path);
    if (to.path == '/login') {
      // if is logged in, redirect to the home page
      // next({
      //   path: '/login'
      // })
      next()
    } else {
      // console.log(!getRouter)
      if (!getRouter) {
        // console.log(routeList)
        if (routeList) {
          // console.log(JSON.parse(routeList));
          getRouter = routeList
          const myRouters = filterAsyncRouter(JSON.parse(routeList))
          // console.log('myRouters',myRouters);
          // router.matcher = myRouters.matcher //match

          // router.addRoutes([myRouters[1]])
          router.addRoutes([{
            path: '*',
            redirect: '/404',
            hidden: true
          }])
          global.antRouter = myRouters
          // console.log(myRouters)
          next({
            ...to,
            replace: true
          })
        } else {
          getRouer(to, next)
          // next('/login')
        }
      } else {
        next()
      }
    }
  } else {
    getRouter = ''
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
    }
  }
});

function getRouer(to, next) {
  api.admin.menuList().then((res) => {
    var arr = res.data
    console.log(arr, 'arr')
    let list = arr.map(item => {
      var l
      if (item.children_menu.length > 0) {
        l = {
          path: item.path,
          component: 'Layout',
          hidden: item.is_show == 1 ? true : false,
          children: item.children_menu,
          redirect: item.url,
          meta: {
            title: item.name
          }
        }
      } else {
        l = {
          path: item.path,
          component: 'Layout',
          hidden: item.is_show == 1 ? true : false,
          children: [{
            path: 'index',
            name: item.name,
            component: item.url,
            meta: {
              title: item.name
            }
          }]
        }
      }
      return l
    })
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].children_menu.length > 0) {
        list[i].children = list[i].children.map(item => {
          return {
            path: item.path,
            component: item.url,
            name: item.name,
            hidden: item.is_show == 1 ? true : false,
            meta: {
              title: item.name
            }
          }
        })
      }
    }
    console.log('list', list)
    localStorage.setItem('myRouters', JSON.stringify(list))
    getRouter = localStorage.getItem('myRouters')
    const myRouters = filterAsyncRouter(JSON.parse(getRouter))
    console.log(myRouters, 'myRouters')
    router.addRoutes(myRouters)
    global.antRouter = myRouters
    next({
      ...to,
      replace: true
    })
  })
}

function filterAsyncRouter(asyncRouterMap) {
  return asyncRouterMap.filter(route => {
    route.path = route.path ? route.path : Math.random().toString(36).slice(-6)
    if (route.component) {
      // layout组件特殊处理
      if (route.component === 'Layout') {
        route.component = Layout
      } else {
        route.component = loadView(route.component)
      }
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children)
    }
    return true
  })
}

// 路由懒加载
export const loadView = (view) => {
  return (resolve) => require([`@/views/${view}`], resolve)
}

// 跳转后自动返回页面顶部
router.afterEach(() => {
  window.scrollTo(0, 0);
})

export default router