
export default {
  namespaced: true,
  state: {
    payType: [
      {
        name: '现金支付',
        value: 1
      },
      {
        name: '微信支付',
        value: 2
      },
      {
        name: '余额支付',
        value: 3
      }, {
        name: '积分兑换',
        value: 4
      },
      {
        name: '条码支付',
        value: 5
      },
      {
        name: '抖音核销',
        value: 10
      }
    ],
  },

}