import request from '@/utils/request'

const orderManage = {
  //订单管理
  //列表
  listOrder(params){
    return request.post('order/list',params)
  },
  //详情
  orderInfo(params){
    return request.post("order/info",params)
  },
  //指派师傅
  assignDriverOrder(params){
    return request.post("order/assign_driver",params)
  },
  //指派拖车师傅
  assrignTuocheOrder(params){
    return request.post("order/assign_tuoche",params)
  },
  //选择师傅
  selDriverOrder(params){
    return request.post("order/sel_driver",params)
  },
  //选择拖车师傅
  selTuocheOrder(params){
    return request.post("order/sel_tuoche",params)
  },
  //取消
  cancelOrder(params){
    return request.post("order/cancel",params) 
  },
  //删除
  delOrder(params){
    return request.post("order/del",params)
  },
  //选择维修师傅
  selServiceUserOrder(params){
    return request.post("order/sel_service_user",params)
  },
  //指派维修人员
  assignSerivceUserOrder(params){
    return request.post("order/assign_service_user",params)
  },
  //订单服务类型
  order_servicetype(params){
    return request.post('order/servicetype',params)
  }
}

export default orderManage;