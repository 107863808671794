import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import "@/components/Css/theme.scss"
import store from './store'
import api from './http/api.js';
import ElTreeGrid from 'element-tree-grid'
import SkuForm from 'vue-sku-form'
import JsonExcel from 'vue-json-excel'
import ColorPicker from 'vue-color-picker'

import draggable from 'vuedraggable';


Vue.config.productionTip = false
Vue.use(ElementUI).use(SkuForm);

// var ElTreeGrid = require('element-tree-grid');
Vue.component(ElTreeGrid.name, ElTreeGrid);
Vue.component('downloadExcel', JsonExcel);
Vue.component('draggable', draggable);
Vue.component('ColorPicker', ColorPicker);

Vue.prototype.$api = api;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
