import request from '@/utils/request'

const storeManage = {
  // 服务类型管理
  // 服务列表
  getServicetype(params) {
    return request.post("servicetype/list", params)
  },
  // 添加编辑
  editServicetype(params) {
    return request.post("servicetype/edit", params)
  },
  // 详情
  infoServicetype(params) {
    return request.post("servicetype/info", params)
  },
  // 删除
  delServicetype(params) {
    return request.post("servicetype/del", params)
  },
  // 选择上级菜单
  statusServicetype(params) {
    return request.post("servicetype/status", params)
  },


  // 门店列表
  // 添加编辑
  editStoreData(params) {
    return request.post("store/edit", params)
  },
  // 详情
  infoStoreData(params) {
    return request.post("store/info", params)
  },
  // 状态修改
  changeStoreStatus(params) {
    return request.post("store/status", params)
  },
  // 删除
  delStoreData(params) {
    return request.post("store/del", params)
  },
  // 列表
  getStoreList(params) {
    return request.post("store/list", params)
  },
  //改变接送状态
  changeIstake(params) {
    return request.post("store/take_status", params)
  },
}

export default storeManage;