import request from '@/utils/request'

const staffManage = {
  // 员工管理
  // 列表
  listStoreUser(params) {
    return request.post("store_user/list", params)
  },
  //添加编辑
  editStoreUser(params) {
    return request.post("store_user/edit", params)
  },
  //闲情
  infoStoreUser(params) {
    return request.post("store_user/info", params)
  },
  //状态
  statusStoreUser(params) {
    return request.post("store_user/status", params)
  },
  //删除
  delStoreUser(params) {
    return request.post("store_user/del", params)
  },
  //选择门店
  selStoreUser(params) {
    return request.post("store_user/sel_store", params)
  },
 
  //选择门店
  selStoreStoreUser(params){
    return request.post("store_user/sel_store",params)
  },

}

export default staffManage;