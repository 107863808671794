
export default {
  namespaced: true,
  state: {
    token: '',
    user: ''
  },
  mutations: {
    setToken(state, paylaod) {    // 存入token
      state.token = paylaod
    },
    delToken(state, paylaod) {  // 清空token
      state.token = paylaod
    },
    setUser(state, paylaod) {    // 存入用户信息
      state.user = paylaod
    },
    delUser(state, paylaod) {  // 清空用户信息
      state.user = paylaod
    },
  },
  actions: {},
}