import request from '@/utils/request'

const admin = {
  // 登录
  login(data) {
    return request({
      url: "login/login",
      method: 'POST',
      data,
    })
  },
  // 获取验证码
  code() {
    return request({
      url: "/login/captcha",
      method: 'POST',
    })
  },
  //菜单列表
  menuList(data) {
    return request({
      url: "/index/menu_list",
      method: 'POST',
      data
    })
  },
  // 注册
  register(data) {
    return request({
      url: "",
      method: 'POST',
      data,
    })
  },
  // 注销
  logout() {
    return request({
      url: "",
      method: 'GET',
    })
  },
  // 获取当前管理员信息
  currentUserInfo() {
    return request({
      url: "",
      method: 'GET',
    })
  },
}

export default admin;