export default {
  namespaced: true,
  state: {
    carbaselist: [],
    pageList:[]
  },
  getters: {
    getpageList(state){
      return state.pageList
    },
    getcarbaselist(state){
      return state.carbaselist
    },
    // carbaselist(state) {
    //   console.log(state);
    //   console.log(state.carbaselist.slice(0,5));
    //   return state.carbaselist
    //   // return state.carbaselist.slice((page - 1)*size,page*size)
    // },
  },
  mutations: {
    setcarbaselist(state, data) {
      state.carbaselist = data
    },
    setpageList(state,data){
      state.pageList = state.carbaselist.slice((data.page - 1)*data.size ,data.page *data.size)
    }
  }
}