import request from '@/utils/request'

const powerManage = {
  // 权限管理
  // 权限列表
  getPermissionsList(data) {
    return request({
      url: "permissions/list",
      method: 'POST',
      data
    })
  },
  // 添加编辑权限
  editPermissionsData(data) {
    return request({
      url: "permissions/edit",
      method: 'POST',
      data,
    })
  },
  // 删除权限
  delPermissionsData(data) {
    return request({
      url: "permissions/del",
      method: 'POST',
      data,
    })
  },
  // 权限详情
  infoPermissionsData(data) {
    return request({
      url: "permissions/info",
      method: 'POST',
      data,
    })
  },
  // 选择上级菜单
  getPermissionsMenuList(data) {
    return request({
      url: "permissions/menu_list",
      method: 'POST',
      data,
    })
  },


  // 角色管理
  // 添加编辑角色
  editRolesData(data) {
    return request({
      url: "roles/edit",
      method: 'POST',
      data,
    })
  },
  // 删除权限
  delRolesData(data) {
    return request({
      url: "roles/del",
      method: 'POST',
      data,
    })
  },
  // 状态修改
  changeRolesStatus(data) {
    return request({
      url: "roles/status",
      method: 'POST',
      data,
    })
  },
  // 角色列表
  getRolesList() {
    return request({
      url: "roles/list",
      method: 'POST',
    })
  },
  // 角色详情
  infoRolesData(data) {
    return request({
      url: "roles/info",
      method: 'POST',
      data,
    })
  },
  // 获取所有权限
  getAllPermissions() {
    return request({
      url: "roles/all_permissions",
      method: 'POST',
    })
  },


  // 人员管理
  // 添加编辑人员
  editAdminData(data) {
    return request({
      url: "admin/edit",
      method: 'POST',
      data,
    })
  },
  // 删除人员
  delAdminData(data) {
    return request({
      url: "admin/del",
      method: 'POST',
      data,
    })
  },
  // 人员列表
  getAdminList(data) {
    return request({
      url: "admin/list",
      method: 'POST',
      data,
    })
  },
  // 选择角色
  selAdminRole() {
    return request({
      url: "admin/sel_role",
      method: 'POST',
    })
  },
  // 人员详情
  infoAdmin(data) {
    return request({
      url: "admin/info",
      method: 'POST',
      data,
    })
  },
}

export default powerManage;