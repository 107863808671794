<template>
  <el-container id="container">
    <el-header height="77px" class="header">
      <div class="left">
        <img class="log" src="@/assets/Images/logo-gai.png" alt="">
        <div class="text">车馆家总后台</div>
      </div>
      <div class="right">
        <div class="time">
          <p>{{ nowYear }}</p>
          <p>{{ nowTime }}</p>
        </div>
        <div class="line"></div>
        <div class="text">
          <p class="top">当前用户</p>
          <p class="name">{{ userName }}</p>
        </div>
        <i class="el-icon-switch-button" @click="$router.push('/login')"></i>
      </div>
    </el-header>
    <el-container>
      <!-- <el-aside width="238px"> -->
      <el-aside width="238px">
        <SideBar></SideBar>
      </el-aside>
      <!-- <el-main><router-view></router-view></el-main> -->
      <el-container class="right-container">
        <el-header style="padding: 0;height:35px; background-color:#f1f1f1 ">
          <div class="right-header" style="height:100%">
            <div class="right-header-tags" style="height:100%">
              <LayoutTabs></LayoutTabs>
            </div>
          </div>
        </el-header>
        <el-main style="padding: 0;" class="main_cont">
          <!-- 二级路由出口 -->
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
import SideBar from "@/components/SideBar";
import LayoutTabs from "@/components/LayoutTabs";
import dayjs from 'dayjs'

export default {
  components: { SideBar, LayoutTabs },
  data() {
    return {
      userName: "",
      nowYear: "",
      nowTime: '',

    };
  },
  created() {

  },
  mounted() {
    this.nowTimes();
    this.userName = localStorage.getItem("userName")
  },
  beforeDestroy() {
    this.clear()
  },
  methods: {

    // 实时刷新当前时间，格式化
    nowTimes() {
      this.nowYear = dayjs().format('YYYY-MM-DD');
      this.nowTime = dayjs().format('HH:mm')
      setInterval(this.nowTimes, 1000);
      this.clear()
    },
    //清除定时器
    clear() {
      clearInterval(this.nowTimes)
      this.nowTimes = null;
    }
  }
};
</script>
<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
}

::v-deep .el-dropdown-menu__item {
  text-align: center;
}
::v-deep .el-aside{
  min-height: calc(100vh - 77px);
}
#container {
  min-height: 10;

  .header {
    color: rgba(255, 255, 255, 1);
    text-align: center;
    background-color: rgba(23, 35, 61, 1);
    display: flex;
    justify-content: space-between;
    line-height: 77px;

    .left {
      display: flex;
      margin-left: 41px;
      align-items: center;
      .log {
        height: 60px;
        width: 60px;
      }

      .text {
        // margin-left: 91px;
        color: rgba(255, 255, 255, 1);
        font-size: 24px;
        margin-left: 20px;
      }
    }

    .right {
      display: flex;
      height: 77px;

      .time {
        // padding-top: 20px;
        // width: 103px;
        // line-height: 20px;
        font-size: 16px;
        word-wrap: break-word;
        overflow: hidden;

        p {
          line-height: 0;
          margin-top: 26px;
        }
      }

      .line {
        margin: 8px 20px 0 15px;
        height: 59px;
        width: 0;
        border: 1px solid rgba(255, 255, 255, 1);
      }

      .text {
        font-weight: normal;
        font-size: 16px;
        padding-top: 22px;

        .top {
          line-height: 12px;
        }

        .name {
          line-height: 33px;
          color: #F0303D;
        }
      }

      .el-icon-switch-button {
        font-size: 30px;
        margin: 25px 37px 0 59px;
      }
    }
  }

  .el-aside {
    background-color: #495061;
    // height: auto;
    // overflow: hidden;
  }

  .el-main {
    color: #323232;
    background-color: #F5F8FF;
  }
}
::v-deep .right-container{
  overflow-x: hidden;
  // height: calc(100vh - 60px);
}
</style>