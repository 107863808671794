<template>
  <div v-if="!item.hidden" class="sidebar">
    <!-- <template
      v-if="hasOneShowingChild(item.children, item) && (!onlyOneChild.children || onlyOneChild.noShowingChildren) && !item.alwaysShow">
      <app-link v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.path)">
        <el-menu-item :index="resolvePath(onlyOneChild.path)" :class="{ 'submenu-title-noDropdown': !isNest }">
          <item :icon="onlyOneChild.meta.icon || (item.meta && item.meta.icon)" :title="onlyOneChild.meta.title" />
        </el-menu-item>
      </app-link>
    </template>
    <el-submenu v-else ref="subMenu" :index="resolvePath(item.path)" popper-append-to-body>
      <template slot="title">
        <item v-if="item.meta" :icon="item.meta && item.meta.icon" :title="item.meta.title" />
      </template>
      <sidebar-item v-for="child in item.children" :key="child.path" :is-nest="true" :item="child"
        :base-path="resolvePath(child.path)" class="nest-menu" />
    </el-submenu> -->

    <el-submenu ref="subMenu" :index="resolvePath(item.url)" popper-append-to-body >
      <template slot="title">
        <i class="iconfont icon-magin" :class="item.meta && item.meta.icon"></i>
        <span>{{ item.name}}</span>
      </template>
      <!-- <sidebar-item v-for="child in item.children_menu" :key="child.url" :is-nest="true" :item="child"
        :base-path="resolvePath(child.url)" class="nest-menu" /> -->
      <div class="itemTabs">
        <el-menu-item v-for="child in item.children_menu"  :key="child.id"
        :class="{ bgc: defaultValue == item.url + '/' + child.url }" :index="item.url + '/' + child.url">{{
          child.name }}</el-menu-item>
      </div>
    </el-submenu>
  </div>
</template>

<script>
// import path from 'path'
import path from 'path-browserify'
import { isExternal } from '@/utils/validate'
// import Item from './Item'
// import AppLink from './Link'
// import FixiOSBug from './FixiOSBug'

export default {
  name: 'SidebarItem',
  // components: { Item, AppLink },
  // mixins: [FixiOSBug],
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    },
    defaultValue: {
      type: String,
    }
  },
  data() {
    // To fix https://github.com/PanJiaChen/vue-admin-template/issues/237
    // TODO: refactor with render function
    this.onlyOneChild = null
    return {}
  },
  created() {
    // console.log('item', path.resolve(this.item.path))
    // console.log('item', this.item)
    // let arr = this.routes.splice(2,1)
    // console.log('route', arr)
   
  },
  computed: {
  },
  methods: {
    // hasOneShowingChild(children = [], parent) {
    //   const showingChildren = children.filter(item => {
    //     if (item.hidden) {
    //       return false
    //     } else {
    //       // Temp set(will be used if only has one showing child)
    //       this.onlyOneChild = item
    //       return true
    //     }
    //   })

    //   // When there is only one child router, the child router is displayed by default
    //   if (showingChildren.length === 1) {
    //     return true
    //   }
    //   // Show parent if there are no child router to display
    //   if (showingChildren.length === 0) {
    //     this.onlyOneChild = { ...parent, path: '', noShowingChildren: true }
    //     return true
    //   }
    //   return false
    // },
    resolvePath(routePath) {
      if (isExternal(routePath)) {
        return routePath
      }
      if (isExternal(this.basePath)) {
        return this.basePath
      }
      return path.resolve(this.basePath, routePath)
    },
    // open(key,keyPath){
    //     console.log(key,keyPath);
    // }
  }
}
</script>

<style scoped lang="scss">
.icon-magin {
  margin-right: 7px;
  margin-left: 5px;
}
.sidebar{
  background-color: #495061;
}
::v-deep .el-submenu__title {
  color: #ffffff;
  background-color: #495061;

  i {
    color: #ffffff;
  }
}
::v-deep .el-submenu__title {
  color: #ffffff;
  background-color: #495061;

  i {
    color: #ffffff;
  }
}
::v-deep .el-submenu__title:hover {
  color: #ffffff;
  background-color: #16C2B2;
}

::v-deep .el-menu-item {
  color: #ffffff;
  background-color: #17233D;

  i {
    color: #ffffff;
  }
}

::v-deep .el-menu-item:hover {
  color: #ffffff;
  background-color: #16C2B2;
}

::v-deep .el-menu-item.is-active {
  color: #ffffff;
  background-color: #16C2B2;
}

.bgc {
  background-color: #FFF6F0;
  position: relative;
}

.bgc::before {
  content: '';
  position: absolute;
  right: 2px;
  bottom: 5px;
  width: 3px;
  height: 40px;
  background-color: #E1F7F5;
}
</style>
