import request from '@/utils/request'
const washTool = {
  //自助洗车列表
  machine_listData(params) {
    return request.post("machine/list", params)
  },
  //添加自助洗车机
  machine_add(params) {
    return request.post("machine/add", params)
  },
  //自助机修改
  machine_edit(params) {
    return request.post("machine/edit", params)
  },
  //自助机删除
  machine_del(params) {
    return request.post("machine/del", params)
  },
  //收费标准列表
  standard_list(params) {
    return request.post("standard/list", params)
  },
  //收费标准编辑
  standard_edit(params) {
    return request.post("standard/edit", params)
  },
  //自助机服务类型编辑
  //  standard_type_edit(params){
  //   return request.post("standard/type_edit",params)
  // },
  //自助机服务类型列表
  standard_type_list(params) {
    return request.post("standard/type_list", params)
  },
  //收费标准类型
  standard_types(params) {
    return request.post("standard/types", params)
  },

  /////////////套餐
  //套餐列表
  thali_list(params) {
    return request.post("thali/list", params)
  },
  //套餐编辑
  thali_edit(params) {
    return request.post("thali/edit", params)
  },
  //套餐删除
  thali_del(params) {
    return request.post("thali/del", params)
  },
  //套餐状态
  thali_edit_status(params) {
    return request.post("thali/edit_status", params)
  },
  //套餐详情
  thali_show(params) {
    return request.post("thali/show", params)
  },
  //套餐排序
  thali_edit_sort(params) {
    return request.post("thali/edit_sort", params)
  },

  //收费标准收费标准收费标准收费标准收费标准收费标准收费标准收费标准
  //收费标准列表
  standard_list(params) {
    return request.post('standard/list', params)
  },
  //收费标准编辑
  standard_edit(params) {
    return request.post("standard/edit", params)
  },
  //自助机服务类型编辑standard/del
  standard_type_edit(params) {
    return request.post("standard/type_edit", params)
  },
  //洗车记录
  wash_logs(params) {
    return request.post("wash/logs", params)
  },
  //收费标准删除
  standard_del(params) {
    return request.post("standard/del", params)
  },
  //自助机服务类型列表
  standard_type_list(params) {
    return request.post("standard/type_list", params)
  },
  //收费标准类型
  standard_types(params) {
    return request.post("standard/types", params)
  },
  //自助机服务类型删除
  standard_type_del(params) {
    return request.post("standard/type_del", params)
  },

  /////////////////////////////////分销
  /////////////////////////////////分销
  //获取
  distrib_get(params) {
    return request.post('distrib/get', params)
  },
  //分销设置
  distrib_setting(params) {
    return request.post("distrib/setting", params)
  },
  //分销佣金设置
  distrib_kickback(params) {
    return request.post('distrib/kickback', params)
  },
  //分销商列表
  share_list(params) {
    return request.post('share/list', params)
  },
  //分销商编辑备注
  share_edit(params) {
    return request.post('share/edit', params)
  },
  //分销商详情
  share_info(params) {
    return request.post('share/info', params)
  },
  //分销商删除
  share_del(params) {
    return request.post('share/del', params)
  },
  //分销商审核
  share_status(params) {
    return request.post('share/status', params)
  },
  //分销商下线列表
  share_sub_list(params) {
    return request.post('share/sub_list', params)
  },
  //佣金记录列表
  kickback_list(params) {
    return request.post('kickback/list', params)
  },
  //佣金提现驳回
  kickback_check(params) {
    return request.post('kickback/check', params)
  },
  //佣金提现打款
  kickback_payment(params) {
    return request.post('kickback/payment', params)
  },
  //分销订单列表
  share_order_list(params) {
    return request.post('share/order_list', params)
  },
  //提现记录
  share_cash_log(params) {
    return request.post("share/cash_log", params)
  },

  // 提现审核
  share_cash_status(params) {
    return request.post('share/cash_status', params)
  },

  //导出分销提现
  share_export(params) {
    return request.get('share/export', params)
  },
  //导出自助洗车
  order_header(params) {
    return request.get('order/header', params)
  },

}
export default washTool