import request from '@/utils/request'

const userManage = {
  // 用户管理
  //列表
  listUser(params){
    return request.post("user/list",params)
  },
  //状态
  statusUser(params){
    return request.post("user/status",params)
  },
  //车辆信息
  infoCar(params){
    return request.post("user/car_info",params)
  },
  //洗车记录
  washCar(params){
    return request.post('user/xiche_list',params)
  },
  //详情
  infoUser(params){
    return request.post('user/info',params)
  },
  //充值
  rechargeUser(params){
    return request.post('user/recharge',params)
  },
  //消费
  xiaoFeiUser(params){
    return request.post("user/xiaofei",params)
  },
  //积分充值消费
  jiFenUser(params){
    return request.post('user/jifen',params)
  },
  //余额充值消费记录
  rechargeListUser(params){
    return request.post('user/recharge_list',params)
  },
  //积分充值消费记录
  jifenList(params){
    return request.post('user/jifen_list',params)
  },

  //会员等级
  //列表
  listUserLevel(params){
    return request.post("user_level/list",params)
  },
  //添加编辑
  editUserLevel(params){
    return request.post("user_level/edit",params)
  },
  //详情
  infoUserLevel(params){
    return request.post("user_level/info",params)
  },
  //状态
  statusUserLevel(params){
    return request.post("user_level/status",params)
  },
  //删除
  delUserLevel(params){
    return request.post("user_level/del",params)
  },
  //导出
  herderUser(params){
    // return request.get("user/header")
    return  request({
      method:'get',
      url:'user/header',
      params,
      responseType: 'blob',
    })
  },
  //会员等级购买记录
  user_level_buy_log(params){
    return request.post('user/level_buy_log',params)
  },
  //留言管理
  //留言管理列表
  feedback_list(params){
    return request.post("feedback/list",params)
  },
  //删除留言
  feedback_del(params){
    return request.post("feedback/del",params)
  }
}

export default userManage;