import request from '@/utils/request'

const marketTools = {
  //卡卷管理
  //列表
  listCoupon(params) {
    return request.post('coupon/list', params)
  },
  //添加编辑 
  editCoupon(params){
    return request.post("coupon/edit",params)
  },
  //详情
  infoCoupon(params){
    console.log('params',params);
    return request.post("coupon/info",params)
  },
  //状态
  statusCoupon(params){
    return request.post("coupon/status",params)
  },
  //删除
  delCoupon(params){
    return request.post("coupon/del",params)
  }
}

export default marketTools;