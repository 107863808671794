<template>
  <div id="app" class="home_box" :style="style">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rate: 1, // 用于判断整体缩小比例是多少
      style: null,
    }
  },
  watch: {
    '$route': function (to, from) {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    }
  },
  mounted() {
    this.resize_window()
    window.addEventListener('resize', () => {
      this.resize_window()
    })
  },
  destroyed() {
    window.removeEventListener('resize', () => {
      this.resize_window()
    })
  },
  methods: {
    resize_window() {
      const widthRatio = Number(document.documentElement.clientWidth / 1920)
      const height = Number(document.documentElement.clientHeight)
      this.rate = widthRatio;
      this.style = {
        transformOrigin: 'left top',
        transform: `scale(${this.rate},${this.rate})`,
        '-webkit-transform': `scale(${this.rate},${this.rate})`,
        '-moz-transform': `scale(${this.rate},${this.rate})`,
        '-o-transform': `scale(${this.rate},${this.rate})`,
        '-ms-transform': `scale(${this.rate},${this.rate})`,
      };
    },
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
html, body {
  height: 100%;
  overflow-x: hidden;
}
#app {
  width: 1920px;
  overflow-x: hidden;

}

.dialog-footer {
  display: inline-block;
  display: flex;
  justify-content: space-evenly;
}

.footer {
  display: inline-block;
  display: flex;
  justify-content: space-evenly;

}

h3 {
  text-align: center !important;
  margin-bottom: 20px;
}

.el-btn {
  display: flex;
  justify-content: space-evenly;
}

/* @import url(@/components/Css/editor.scss); */
</style>
