import request from '@/utils/request'

const dataManage = {
  //用户数据
  indexData(params){
    return request.post("data/index",params)
  },
  //平台数据
  dataData(params){
    return request.post("data/data",params)
  },
  //用户列表
  listData(params){
    return request.post("data/list",params)
  }
}

export default dataManage;