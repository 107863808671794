import axios from 'axios'
import { baseUrl} from "./baseUrl"

// import cookie from 'js-cookie'

// 创建axios实例
const service = axios.create({
  // baseURL: 'https://api.car.scxyjnet.com/admin', // 测试后端请求端口设置
  //  baseURL: 'https://carapi.scxyjnet.com/admin', // 正式后端请求端口设置
      // baseURL:process.env.VUE_APP_API_URL,
     baseURL:baseUrl,
     timeout: 15000 // 请求超时时间
})

console.log('process.env.VUE_APP_API_URL',process.env.VUE_APP_API_URL);
// 请求拦截器
service.interceptors.request.use(
  config => {
    // config.headers['Content-Type'] = 'application/json;charset=utf-8';
    if (localStorage.getItem("token")) {
      // console.log(123, localStorage.getItem("token"));
      config.headers['token'] = `${localStorage.getItem("token")}`
      // config.headers['Authorization'] = `bearer e839cc7e1b3f164a9af0847d92d7e63f457d8f5f09007dae`
    }
    return config
  },
  err => {
    return Promise.reject(err)
  })

// 响应拦截器
service.interceptors.response.use(function (response) {
  let res = response.data;
  // 如果是返回的文件
  if (response.config.responseType === 'blob') {
    return res
  }
  // 兼容服务端返回的字符串数据
  if (typeof res === 'string') {
    // 如果是json字符串，将json字符串转换为json对象
    res = res ? JSON.parse(res) : res
  }
  return res;
  // return response
}, function (err) {
  if (err && err.response && err.response.status) {
    switch (err.response.status) {
      case 401:
        err.message = '身份认证已过期'
        // alert('身份认证已过期，请重新登录');
        router.push('/login')
        break;
      case 404:
        err.message = '资源找不到'
        break;
      case 500:
        err.message = '服务器错误'
        break;
      default:
        err.message = '服务器错误'
        break;
    }
  } else {
    err.message = '服务器错误'
  }
  return Promise.reject(err.message)
});
export default service