<template>
  <div class="caidan">
    <el-menu router :default-active="defaultValue" :unique-opened="true" class="el-menu-vertical-demo" @open="handleOpen"
      @select="change" @close="handleClose">

      <SidebarItem v-for="(route) in routes" :key="route.id" :item="route" :base-path="route.url"
        :defaultValue="defaultValue" />
    </el-menu>
  </div>

</template>

<script>
import SidebarItem from './SidebarItem'
// import variables from '@/components/Css/variables.scss'

export default {
  components: { SidebarItem },
  data() {
    return {
      defaultValue: '/dataManage/dataAnalysis',
      routes:[]
    }
  },
  created() {
    this.defaultValue = this.$store.state?.tabs.path;
    this.getMeunList()
    // console.log('routes', this.routes)
  },
  methods: {
    handleOpen(key, keyPath) {
      // console.log(key,keyPath);
    },
    handleClose(key, keyPath) {
    },
    change(index) {
      this.defaultValue = index
    },
    getMeunList(){
      this.$api.admin.menuList().then(res=>{
        this.routes = res.data
        // console.log('this.routes',res.data);
      })
    }
  },
  watch: {
    '$store.state.tabs.tabs'() {
      this.$store.commit('tabs/getPath')
      this.defaultValue = this.$store.state.tabs?.path // 设置每次默认选中
    }
  },
  computed: {
  },
  
}

</script>

<style scoped lang="scss">
.caidan{
  height: 850px;
  overflow-y: scroll;
}
.icon-magin {
  margin-right: 7px;
  margin-left: 5px;
}
::v-deep .el-menu{
  background-color: #495061;
}
::v-deep .el-submenu__title {
  color: #ffffff;
  background-color: #495061;

  i {
    color: #ffffff;
  }
}

::v-deep .el-submenu__title:hover {
  color: #ffffff;
  background-color: #16C2B2;
}

::v-deep .el-menu-item {
  color: #ffffff;
  background-color: #17233D;

  i {
    color: #ffffff;
  }
}

::v-deep .el-menu-item:hover {
  color: #ffffff;
  background-color: #16C2B2;
}

::v-deep .el-menu-item.is-active {
  color: #ffffff;
  background-color: #16C2B2;
}

.bgc {
  background-color: #FFF6F0;
  position: relative;
}

.bgc::before {
  content: '';
  position: absolute;
  right: 2px;
  bottom: 5px;
  width: 3px;
  height: 40px;
  background-color: #E1F7F5;
}
</style>