export default {
    namespaced: true,
    state: {
        local: '',
    },
    mutations: {
        setlocal(state, data) {
            state.local = data
        }
    }
}