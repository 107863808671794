import request from '@/utils/request'

const serviceProducts = {
  // 产品分类管理
  // 添加编辑
  editCategory(params) {
    return request.post("category/edit", params)
  },
  //获取所有安装位置
  getPosition(params) {
    return request.post("product/sel_position", params)
  },
  // 状态
  statusCategory(params) {
    return request.post("category/status", params)
  },
  // 删除
  delCategory(params) {
    return request.post("category/del", params)
  },
  // 全部树形列表
  allCategory(params) {
    return request.post("category/all", params)
  },
  // 全部分级列表
  listCategory(params) {
    return request.post("category/list", params)
  },
  // 详情
  infoCategory(params) {
    return request.post("region/info", params)
  },


  // 安装位置管理
  // 添加编辑
  editInstallposition(params) {
    return request.post("installposition/edit", params)
  },
  // 详情
  infoInstallposition(params) {
    return request.post("installposition/info", params)
  },
  // 状态
  statusInstallposition(params) {
    return request.post("installposition/status", params)
  },
  // 删除
  delInstallposition(params) {
    return request.post("installposition/del", params)
  },
  // 列表
  listInstallposition(params) {
    return request.post("installposition/list", params)
  },


  // 产品管理
  // 添加编辑
  editProduct(params) {
    return request.post("product/edit", params)
  },
  // 状态
  statusProduct(params) {
    return request.post("product/status", params)
  },
  // 详情
  infoProduct(params) {
    return request.post("product/info", params)
  },
  // 删除
  delProduct(params) {
    return request.post("product/del", params)
  },
  // 列表
  listProduct(params) {
    return request.post("product/list", params)
  },


  //套餐服务管理
  //列表
  listStoreService(params) {
    return request.post("store_service/list", params)
  },
  //添加编辑
  editStoreService(params) {
    console.log(params);
    return request.post("store_service/edit", params)
  },
  //详情
  infoStoreService(params) {
    return request.post("store_service/info", params)
  },
  //状态
  statusStoreService(params) {
    return request.post("store_service/status", params)
  },
  //删除
  delStoreService(params) {
    return request.post("store_service/del", params)
  },
  //选择系统服务类型
  selServiceTypeStoreService(params) {
    return request.post("store_service/sel_service_type", params)
  },
  //选择系统服务类型
  // selServiceTypeStoreService(params) {
  //   return request.post("store_service/sel_service_type", params)
  // },
  //选择套餐服务分类 
  selStoreTypeStoreService(params) {
    return request.post("store_service/sel_store_type", params)
  },
  //选择产品
  selProductStoreService(params) {
    return request.post("store_service/sel_product", params)
  },
  //添加编辑服务
  serviceTypeStoreService(params) {
    return request.post("store_service/service", params)
  },

  //套餐服务分类管理
  //列表 store_type/list
  listStoreType(params) {
    return request.post("store_type/list", params)
  },
  //添加编辑 store_type/edit
  editStoreType(params){
    return request.post("store_type/edit",params)
  },
  //详情 store_type/info
  infoStoreType(params){
    return request.post("store_type/info",params)
  },
  //状态 store_type/status
  statusStoreType(params){
    return request.post("store_type/status",params)
  },
  //删除store_type/del
  delStoreType(params){
    return request.post("store_type/del",params)
  },
  //选择系统服务类型 store_type/sel_service_type
  selServiceTypeStoreType(params){
    return request.post("store_type/sel_service_type",params)
  },


  //系统服务类型管理
  //服务列表
  listServiceType(params){
    return request.post("servicetype/list",params)
  },
  //添加编辑
  editServiceType(params){
    return request.post("servicetype/edit",params)
  },
  //详情
  infoServiceType(params){
    return request.post("servicetype/info",params)
  },
  //删除
  delServiceType(){
    return request.post("servicetype/del",params)
  },


  // 单品分类的树形列表
  // allCategory(params){
  //   return request.post("category/all",params)
  // },

  //批量上下架
  listStatusStoreService(params){
    return request.post("store_service/list_status",params)
  }
}

export default serviceProducts;