import request from '@/utils/request'
const diy = {
  // 模板添加
  template_add(params){
    return request.post('template/add',params)
  },
  //模板列表template/list
  template_list(params){
    return request.post('template/list',params)
  },
  //添加自定义页面 page/add
  page_add(params){
    return request.post('page/add',params)
  },
   //自定义列表
   page_list(params){
    return request.post('page/list',params)
  },
  // 全部地区树形列表
  region_all(params){
    return request.post('region/all',params)
  },
  // 自定义页面详情
  page_show(params){
    return request.post('page/show',params)
  },
  //自定义页面编辑
  page_edit(params){
    return request.post('page/edit',params)
  },
  //页面删除
  page_del(params){
    return request.post('page/del',params)
  },
  //模板列表
  template_list(params){
    return request.post('template/list',params)
  },
  //模板详情
  template_show(params){
    return request.post('template/show',params)
  },
  //模板编辑
  template_edit(params){
    return request.post("template/edit",params)
  },
  //模板删除
  template_del(params){
    return request.post("template/del",params)
  }
}
export default diy;