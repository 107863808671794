// tabs栏去重
export const newArray = (array) => {
  return array.reduce((accumulator, currentObject) => {
    // 检查当前对象的 title 是否已经存在于累加器中的对象中
    const existingObject = accumulator.find(obj => obj.title === currentObject.title);

    // 如果不存在则添加到累加器中，实现去重
    if (!existingObject) {
      accumulator.push(currentObject);
    }

    return accumulator;
  }, []);
}